
      @import "regions/kursk/styles/themes/muza/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/muza/fonts.scss";
      @import "regions/kursk/styles/themes/muza/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 400px;
  align-items: center;
  justify-content: center;

  .title {
    @include h2;
  }

  .text {
    @include t1;
  }
}
