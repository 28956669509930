@font-face {
  font-family: Golos Text;
  font-style: normal;
  font-weight: 400;
  src: local(''), url('/fonts/GolosText-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Golos Text;
  font-style: normal;
  font-weight: 500;
  src: local(''), url('/fonts/GolosText-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Golos Text;
  font-style: normal;
  font-weight: 600;
  src: local(''), url('/fonts/GolosText-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: Golos Text;
  font-style: normal;
  font-weight: 700;
  src: local(''), url('/fonts/GolosText-Bold.ttf') format('truetype');
  font-display: swap;
}
